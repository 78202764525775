import { Popover, Transition } from "@headlessui/react";
import { getWeekNumber } from "@utils/dateTime";
import clsx from "clsx";
import {
  Icon,
  NavigationCalendars,
  OptionsToggleButton,
} from "components/shared";
import { useLayout } from "contexts/LayoutProvider";
import { useMemo } from "react";
import { AdminDashboardViewMode } from "sections/AdminDashboard/types";
import { useAdminDashboardContext } from "../../AdminDashboardProvider";
import cellRenderer from "./cellRenderer";

type Props = { className?: string };

export const AdminDashboardNavigationCalendars = ({ className }: Props) => {
  const { screenWidth } = useLayout();
  const {
    selectedDate,
    setSelectedDate,
    selectedDateMidweek,
    getWeekDaysRangeString,
    viewMode,
    setViewMode,
  } = useAdminDashboardContext();

  const cellRender = useMemo(() => cellRenderer(selectedDate), [selectedDate]);

  return (
    <Popover
      className={clsx(
        "isolate flex flex-col gap-y-3 items-start px-1 py-3 bg-gray-200/80 backdrop-blur",
        className
      )}
    >
      <div className="flex flex-row justify-between flex-wrap-reverse gap-2 sm:gap-4 w-full">
        <Popover.Button className="inline-flex items-center gap-x-1 text-xl sm:text-2xl font-bold text-slate-800 shrink-0">
          <span>
            Week {getWeekNumber(selectedDateMidweek)}:{" "}
            {getWeekDaysRangeString()}
          </span>
          <Icon icon="chevronDown" size={6} color="text-slate-800" />
        </Popover.Button>

        <OptionsToggleButton
          className="ml-auto min-w-64 sm:min-w-72"
          titleClassName="text-sm sm:text-base font-semibold"
          height="h-6 sm:h-8"
          radius={8}
          options={Object.values(AdminDashboardViewMode)}
          activeTab={Object.values(AdminDashboardViewMode).indexOf(viewMode)}
          setActiveTab={(index) =>
            setViewMode(Object.values(AdminDashboardViewMode)[index])
          }
        />
      </div>
      <Transition
        as={Popover.Panel}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 -translate-y-1"
        enterTo="transform opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 -translate-y-1"
        className="flex flex-row justify-center w-full"
      >
        <NavigationCalendars
          viewDate={selectedDate}
          className="justify-center"
          calendarsWidth={screenWidth - 300}
          cellRenderer={cellRender}
          setViewDate={setSelectedDate}
        />
      </Transition>
    </Popover>
  );
};
