import {
  AccountStatus,
  CohortAssignmentRole,
  CohortSessionTeacherAttendanceStatus,
} from "@generated/graphql";
import { AttendanceRobot } from "components/shared";
import { DynamicTextArea } from "components/shared/Inputs/DynamicTextArea";
import { StaffAssignmentAvatar } from "components/staffAssignments/StaffAssignmentAvatar";
import { useAuth } from "contexts/AuthProvider";
import { useEffect, useMemo, useState } from "react";
import { AttendanceRowData } from "sections/EngagementsDashboard/types";
import { AttendanceStatus } from "types/global";
import { useDebounce } from "use-debounce";
import { AttendanceStatusInput, TeacherSurveyLinks } from "./components";

export type AttendanceUpdate = {
  id: string;
  notes?: string | null;
  status: AttendanceStatus;
  assignmentRole: CohortAssignmentRole;
};

type Props = {
  mutationLoading: boolean;
  attendanceStatusOptions: AttendanceStatus[];
  attendanceRow: AttendanceRowData<AttendanceStatus>;
  onChange: (update: AttendanceUpdate) => void;
  setChangesPending: (changesPending: boolean) => void;
};

export const AttendanceRow = ({
  mutationLoading,
  attendanceStatusOptions,
  attendanceRow: {
    id,
    notes,
    userId,
    cohortId,
    userName,
    userEmail,
    updatedById,
    userAccountRole,
    cohortSessionId,
    attendanceStatus,
    userAccountStatus,
    cohortAssignmentRole,
    statusConditionalElementFn,
  },
  onChange,
  setChangesPending,
}: Props) => {
  const { isMentor } = useAuth();
  const [draftNotes, setDraftNotes] = useState(notes);
  const [draftStatus, setDraftStatus] = useState(attendanceStatus);

  const [debouncedDraftNotes, { isPending: notesIsPending }] = useDebounce(
    draftNotes,
    1000
  );
  const [debouncedDraftStatus, { isPending: statusIsPending }] = useDebounce(
    draftStatus,
    150
  );

  const { debouncePending, changesArePending } = useMemo(() => {
    const debouncePending = notesIsPending() || statusIsPending();
    return {
      debouncePending,
      changesArePending: mutationLoading || debouncePending,
    };
  }, [mutationLoading, notesIsPending, statusIsPending]);

  useEffect(() => {
    if (
      !changesArePending &&
      (debouncedDraftNotes !== notes ||
        debouncedDraftStatus !== attendanceStatus)
    ) {
      onChange({
        id,
        status:
          debouncedDraftStatus ?? CohortSessionTeacherAttendanceStatus.Unknown,
        assignmentRole:
          cohortAssignmentRole ?? CohortAssignmentRole.SubstituteTeacher,
        notes: debouncedDraftNotes === "" ? null : debouncedDraftNotes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDraftNotes, debouncedDraftStatus, onChange]);

  // Sync from outside changes
  useEffect(() => setDraftNotes(notes), [notes]);
  useEffect(() => setDraftStatus(attendanceStatus), [attendanceStatus]);

  useEffect(
    () => setChangesPending(changesArePending),
    [setChangesPending, changesArePending]
  );

  const getAvatarColor =
    attendanceStatus === CohortSessionTeacherAttendanceStatus.Present
      ? "bg-emerald-400"
      : attendanceStatus === CohortSessionTeacherAttendanceStatus.Absent
      ? "bg-rose-400"
      : "bg-slate-200";

  return (
    <div className="flex flex-col w-full gap-2 mt-2">
      <div className="flex flex-col lg:flex-row justify-between gap-4">
        <div className="sm:shrink-0 flex-1 grow min-w-[300px]">
          <StaffAssignmentAvatar
            id={userId}
            fullName={userName ?? ""}
            accountRole={userAccountRole}
            email={userEmail ?? ""}
            className={getAvatarColor}
            cohortAssignmentRole={cohortAssignmentRole}
            isInactive={userAccountStatus === AccountStatus.Inactive}
            actions={
              isMentor && cohortId && cohortSessionId ? (
                <TeacherSurveyLinks
                  revieweeId={userId}
                  cohortId={cohortId}
                  sessionId={cohortSessionId}
                />
              ) : null
            }
          />
        </div>

        <div className="flex w-full flex-1 flex-col items-end justify-between gap-y-2">
          <DynamicTextArea
            value={draftNotes}
            className="w-full"
            onChange={(value) => setDraftNotes(value)}
            debouncePending={debouncePending}
            mutationLoading={mutationLoading}
            placeholder="Add your note..."
            textAreaClassName="border-gray-400"
            rows={2}
          />

          <div className="flex flex-row-reverse flex-wrap w-full justify-between">
            <div className="flex flex-row items-center justify-end gap-x-6">
              <AttendanceRobot
                isAutomaticallyUpdated={
                  !updatedById &&
                  attendanceStatus !==
                    CohortSessionTeacherAttendanceStatus.Unknown
                }
              />
              <div className="flex items-center">
                <AttendanceStatusInput
                  attendanceRowId={id}
                  status={debouncedDraftStatus}
                  attendanceStatusOptions={attendanceStatusOptions}
                  onChange={(status) => setDraftStatus(status)}
                  statusConditionalElementFn={statusConditionalElementFn}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
