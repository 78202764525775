import { gql, useQuery } from "@apollo/client";
import {
  EngagementDetails,
  GetInstructionalSupportWeeksDetailsQuery,
  GetInstructionalSupportWeeksDetailsQueryVariables,
  InstructionalSupportsTab_TutorWeeksItemFragment,
} from "@generated/graphql";
import { Routes } from "@utils/routes";
import { Icon, Link, Table, triggerErrorToast } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { CellProps } from "react-table";
import { useAdminDashboardContext } from "sections/AdminDashboard/AdminDashboardProvider";
import { useInfiniteScroll } from "sections/AdminDashboard/components/AdminDashboardOverview/useInfiniteScroll";
import { AdminMode } from "types/global";

const GET_INSTRUCTIONAL_SUPPORT_FRAGMENT = gql`
  fragment InstructionalSupportsTab_TutorWeeksItem on TutorWeeksItem {
    id
    userId
    userName
    weeksTutoringHoursCount
    weeksTutoringHoursCompleted
    engagementDetails {
      id
      engagementId
      organizationId
      engagementName
      shiftsDetails
    }
    averageAttendance
  }
`;

const GET_INSTRUCTIONAL_SUPPORT_WEEKS_DETAILS = gql`
  query GetInstructionalSupportWeeksDetails($input: AdminDashboardISInput!) {
    getInstructionalSupportWeeksDetails(input: $input) {
      ...InstructionalSupportsTab_TutorWeeksItem
    }
  }
  ${GET_INSTRUCTIONAL_SUPPORT_FRAGMENT}
`;

type Props = {
  dataName: string;
  emptyStateSubtitle: string;
};

export function InstructionalSupportsSummaryTab({
  dataName,
  emptyStateSubtitle,
}: Props) {
  const columns = useColumns();
  const { selectedDate } = useAdminDashboardContext();

  const { data, loading, fetchMore } = useQuery<
    GetInstructionalSupportWeeksDetailsQuery,
    GetInstructionalSupportWeeksDetailsQueryVariables
  >(GET_INSTRUCTIONAL_SUPPORT_WEEKS_DETAILS, {
    variables: {
      input: {
        selectedDate: selectedDate.getTime(),
        isCursor: 0,
      },
    },
    onError: (error) => {
      console.error("Fetching data failed: ", error.message);
      triggerErrorToast({
        message: "Something went wrong while retrieving the data.",
        sub: error.message,
      });
    },
  });

  const { fetchMoreData } = useInfiniteScroll<
    GetInstructionalSupportWeeksDetailsQuery,
    GetInstructionalSupportWeeksDetailsQueryVariables,
    InstructionalSupportsTab_TutorWeeksItemFragment
  >({
    loading,
    fetchMore,
    inputResolver: (isCursor) => ({
      input: { selectedDate: selectedDate.getTime(), isCursor },
    }),
    itemResolver: (data) => data.getInstructionalSupportWeeksDetails,
    idResolver: (item) => item.id,
    dataCombiner: (prev, getInstructionalSupportWeeksDetails) => ({
      ...prev,
      getInstructionalSupportWeeksDetails,
    }),
  });

  const items = data?.getInstructionalSupportWeeksDetails;

  return (
    <Table
      columns={columns}
      data={items ?? []}
      className="max-h-[500px] overflow-y-auto text-start"
      verticalDividers
      pageSize={100}
      emptyIcon={<Icon icon="teacher" size={8} />}
      dataName={dataName}
      loading={loading}
      freezeHeaders
      fetchMoreData={fetchMoreData}
      emptyStateSubtitle={emptyStateSubtitle}
      tableComponentId="instructional-supports-summary-tab"
    />
  );
}

const useColumns = () => {
  const { setAdminMode } = useAuth();

  return [
    {
      accessor: (row: InstructionalSupportsTab_TutorWeeksItemFragment) =>
        row.userName,
      Header: "Name",
      sortType: "string",
      Cell: ({
        row: {
          original: { userId, userName },
        },
      }: CellProps<InstructionalSupportsTab_TutorWeeksItemFragment>) => {
        return (
          <Link
            route={Routes.user.details}
            routeProps={[userId]}
            onClick={() => {
              setAdminMode(AdminMode.Managing, false, Routes.user.details, [
                userId,
              ]);
            }}
          >
            {userName}
          </Link>
        );
      },
    },
    {
      Header: "Engagements & Shifts",
      accessor: (
        row: InstructionalSupportsTab_TutorWeeksItemFragment
      ): EngagementDetails[] => row.engagementDetails,
      Cell: ({
        value,
      }: CellProps<
        InstructionalSupportsTab_TutorWeeksItemFragment,
        EngagementDetails[]
      >) => (
        <Table<EngagementDetails>
          data={value}
          showPagination={false}
          verticalDividers
          className="min-h-0 text-start"
          showHeaders={false}
          columns={[
            {
              accessor: "engagementName",
              sortType: "string",
              width: 380,
              Cell: ({ row }: CellProps<EngagementDetails>) => (
                <Link
                  route={Routes.engagement.details}
                  routeProps={[row.original.engagementId]}
                  onClick={() => {
                    setAdminMode(
                      AdminMode.Managing,
                      false,
                      Routes.engagement.details,
                      [row.original.engagementId]
                    );
                  }}
                >
                  {row.original.engagementName}
                </Link>
              ),
            },
            {
              accessor: "shiftsDetails",

              Cell: ({ row }: CellProps<EngagementDetails>) => (
                <div className="flex flex-col gap-x-1">
                  {row.original.shiftsDetails.map((shift) => (
                    <p key={shift}>{shift}</p>
                  ))}
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      accessor: (row: InstructionalSupportsTab_TutorWeeksItemFragment) =>
        row.weeksTutoringHoursCount,
      Header: "Hours/Week",
      sortType: "number",
      Cell: ({
        row: {
          original: { weeksTutoringHoursCount },
        },
      }: CellProps<InstructionalSupportsTab_TutorWeeksItemFragment>) =>
        weeksTutoringHoursCount,
    },
    {
      accessor: (row: InstructionalSupportsTab_TutorWeeksItemFragment) =>
        row.weeksTutoringHoursCompleted,
      Header: "Progress",
      sortType: "number",
      Cell: ({
        row: {
          original: { weeksTutoringHoursCompleted, weeksTutoringHoursCount },
        },
      }: CellProps<InstructionalSupportsTab_TutorWeeksItemFragment>) =>
        `${(
          (weeksTutoringHoursCompleted / weeksTutoringHoursCount) *
          100
        ).toFixed(0)}%`,
    },
    {
      accessor: (row: InstructionalSupportsTab_TutorWeeksItemFragment) =>
        row.averageAttendance,
      Header: "Average Attendance",
      sortType: "number",
      Cell: ({
        row: {
          original: { averageAttendance },
        },
      }: CellProps<InstructionalSupportsTab_TutorWeeksItemFragment>) =>
        `${(averageAttendance * 100).toFixed(0)}%`,
    },
  ];
};
