import { Transition } from "@headlessui/react";

type Props = {
  className?: string;
  show: boolean;
  children: React.ReactNode;
};

export function AdminDashboardViewModeTransitionContainer({
  className,
  show,
  children,
}: Props) {
  return (
    <Transition
      className={className}
      show={show}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-0"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
}
