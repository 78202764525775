import { gql, useQuery } from "@apollo/client";
import {
  EngagementStaffingSummary_StaffingEngagementItemFragment,
  EngagementsStaffingSummaryQuery,
  EngagementsStaffingSummaryQueryVariables,
} from "@generated/graphql";
import { normalizeDateFromUTCDateTime } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import { Icon, Link, Table, triggerErrorToast } from "components/shared";
import {
  getSubHeaderPillItem,
  renderIndicatorCell,
} from "components/shared/Table/helpers";
import { useAuth } from "contexts/AuthProvider";
import { getShortReadableDate } from "helpers";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";
import { useAdminDashboardContext } from "sections/AdminDashboard/AdminDashboardProvider";
import { useInfiniteScroll } from "sections/AdminDashboard/components/AdminDashboardOverview/useInfiniteScroll";
import { StaffType, staffTypeColors } from "sections/AdminDashboard/types";
import { AdminMode } from "types/global";

const ENGAGEMENT_STAFFING_SUMMARY_ITEM = gql`
  fragment EngagementStaffingSummary_StaffingEngagementItem on StaffingEngagementItem {
    id
    organizationId
    organizationName
    engagementId
    engagementName
    launchDate
    endDate
    cohortsCount
    studentsCount
    staffCount {
      totalCount
      mentorsCount
      tutorsCount
      instructionalSupportsCount
    }
  }
`;

const GET_ENGAGEMENTS_STAFFING_SUMMARY = gql`
  query EngagementsStaffingSummary($input: AdminDashboardStaffingInput!) {
    getStaffingEngagements(input: $input) {
      ...EngagementStaffingSummary_StaffingEngagementItem
    }
  }
  ${ENGAGEMENT_STAFFING_SUMMARY_ITEM}
`;

type Props = {
  dataName: string;
  emptyStateSubtitle: string;
};

export function EngagementStaffingSummaryTab({
  dataName,
  emptyStateSubtitle,
}: Props) {
  const columns = useColumns();
  const { selectedDate } = useAdminDashboardContext();

  const { data, loading, fetchMore } = useQuery<
    EngagementsStaffingSummaryQuery,
    EngagementsStaffingSummaryQueryVariables
  >(GET_ENGAGEMENTS_STAFFING_SUMMARY, {
    variables: {
      input: {
        selectedDate,
        staffingCursor: 0,
      },
    },
    onError: (error) => {
      console.error("Fetching data failed: ", error.message);
      triggerErrorToast({
        message: "Something went wrong while retrieving the data.",
        sub: error.message,
      });
    },
  });

  const { fetchMoreData } = useInfiniteScroll<
    EngagementsStaffingSummaryQuery,
    EngagementsStaffingSummaryQueryVariables,
    EngagementStaffingSummary_StaffingEngagementItemFragment
  >({
    loading,
    fetchMore,
    inputResolver: (staffingCursor) => ({
      input: { selectedDate: selectedDate.getTime(), staffingCursor },
    }),
    itemResolver: (data) => data.getStaffingEngagements,
    idResolver: ({ id }) => id,
    dataCombiner: (prev, getStaffingEngagements) => ({
      ...prev,
      getStaffingEngagements,
    }),
  });

  const items = data?.getStaffingEngagements;

  return (
    <Table
      columns={columns}
      data={items ?? []}
      className="max-h-[500px] overflow-y-auto text-start"
      verticalDividers
      pageSize={100}
      emptyIcon={<Icon icon="engagement" size={8} />}
      dataName={dataName}
      loading={loading}
      freezeHeaders
      fetchMoreData={fetchMoreData}
      emptyStateSubtitle={emptyStateSubtitle}
      tableComponentId="engagements-staffing-summary-tab"
    />
  );
}

function useColumns(): Column<EngagementStaffingSummary_StaffingEngagementItemFragment>[] {
  const { setAdminMode, setActiveRoute } = useAuth();

  return useMemo(() => {
    const { MT, TT, IS, Total } = StaffType;

    const staffTypeMapDetails = [
      { name: "Total", color: staffTypeColors[Total] },
      { name: "Mentor Teachers", color: staffTypeColors[MT] },
      { name: "Instructional Support", color: staffTypeColors[IS] },
      { name: "Tutor Teachers", color: staffTypeColors[TT] },
    ];

    return [
      {
        accessor: "organizationName",
        Header: "Organization",
        sortType: "string",
        Cell: ({
          row: {
            original: { organizationId, organizationName },
          },
        }: CellProps<EngagementStaffingSummary_StaffingEngagementItemFragment>) => (
          <Link
            route={Routes.org.details}
            routeProps={[organizationId]}
            onClick={() => {
              setAdminMode(AdminMode.Staffing);
              setActiveRoute(Routes.org.details, [organizationId]);
            }}
          >
            {organizationName}
          </Link>
        ),
      },
      {
        accessor: "engagementName",
        Header: "Engagement",
        sortType: "string",
        Cell: ({
          row: {
            original: { engagementId, engagementName },
          },
        }: CellProps<EngagementStaffingSummary_StaffingEngagementItemFragment>) => (
          <Link
            route={Routes.engagement.details}
            routeProps={[engagementId]}
            onClick={() => {
              setAdminMode(AdminMode.Staffing);
              setActiveRoute(Routes.engagement.details, [engagementId]);
            }}
          >
            {engagementName}
          </Link>
        ),
      },
      {
        accessor: (row) => new Date(row.launchDate),
        Header: "Launch Date",
        sortType: "datetime",
        Cell: ({
          row: {
            original: { launchDate },
          },
        }: CellProps<EngagementStaffingSummary_StaffingEngagementItemFragment>) =>
          getShortReadableDate(
            normalizeDateFromUTCDateTime(new Date(launchDate ?? 0))
          ),
      },
      {
        accessor: (row) => new Date(row.endDate),
        Header: "End Date",
        sortType: "datetime",
        Cell: ({
          row: {
            original: { endDate },
          },
        }: CellProps<EngagementStaffingSummary_StaffingEngagementItemFragment>) =>
          getShortReadableDate(
            normalizeDateFromUTCDateTime(new Date(endDate ?? 0))
          ),
      },
      {
        accessor: "cohortsCount",
        Header: "Cohorts",
        sortType: "number",
        Cell: ({
          row: {
            original: { cohortsCount },
          },
        }: CellProps<EngagementStaffingSummary_StaffingEngagementItemFragment>) =>
          cohortsCount,
      },
      {
        accessor: "studentsCount",
        Header: "Students",
        sortType: "number",
        Cell: ({
          row: {
            original: { studentsCount },
          },
        }: CellProps<EngagementStaffingSummary_StaffingEngagementItemFragment>) =>
          studentsCount,
      },
      {
        Header: () => (
          <div className="flex gap-2">
            {staffTypeMapDetails.map(({ name, color }) =>
              getSubHeaderPillItem(name, color)
            )}
          </div>
        ),
        id: "staffCount",
        sortType: "number",
        accessor: (row) => row.staffCount.totalCount,
        Cell: ({
          row: {
            original: { staffCount },
          },
        }: CellProps<EngagementStaffingSummary_StaffingEngagementItemFragment>) => {
          const values = [
            staffCount.totalCount,
            staffCount.mentorsCount,
            staffCount.instructionalSupportsCount,
            staffCount.tutorsCount,
          ];

          return (
            <div className="flex gap-2">
              {staffTypeMapDetails
                .map((status, index) => ({
                  ...status,
                  value: values[index],
                }))
                .map(({ name, color, value }) =>
                  renderIndicatorCell(name, color, value)
                )}
            </div>
          );
        },
      },
    ];
  }, [setAdminMode, setActiveRoute]);
}
