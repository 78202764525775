import { breadcrumbs } from "@utils/breadcrumbs";
import { Page, PageHeader } from "components/shared";
import { useAdminDashboardContext } from "./AdminDashboardProvider";
import {
  AdminDashboardCalendar,
  AdminDashboardNavigationCalendars,
  AdminDashboardOverview,
  AdminDashboardViewModeTransitionContainer,
} from "./components";
import { AdminDashboardViewMode } from "./types";

export function AdminDashboard() {
  const { viewMode } = useAdminDashboardContext();
  return (
    <Page className="relative gap-y-5">
      <PageHeader breadcrumbs={[breadcrumbs.home(), breadcrumbs.dashboard()]} />
      <AdminDashboardNavigationCalendars className="sticky top-0 z-10 -mx-4 px-4" />

      <AdminDashboardViewModeTransitionContainer
        className="bg-white pl-2 pr-1 rounded-lg"
        show={viewMode === AdminDashboardViewMode.Calendar}
      >
        <AdminDashboardCalendar className="h-[85vh]" />
      </AdminDashboardViewModeTransitionContainer>

      <AdminDashboardViewModeTransitionContainer
        show={viewMode === AdminDashboardViewMode.Overview}
      >
        <AdminDashboardOverview />
      </AdminDashboardViewModeTransitionContainer>
    </Page>
  );
}
