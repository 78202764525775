import { AccountRole, CohortAssignmentRole, User } from "@generated/graphql";
import { getAccountRoleText } from "@utils/labels";
import clsx from "clsx";
import {
  AttendanceScorecardModal,
  CohortAssignmentRoleText,
  Icon,
  LoadingText,
} from "components/shared";
import { useState } from "react";

type Props = {
  id: User["id"];
  loading?: boolean;
  className?: string;
  isInactive: boolean;
  email: User["email"];
  actions?: React.ReactNode;
  accountRole?: AccountRole;
  fullName: User["fullName"];
  cohortAssignmentRole?: CohortAssignmentRole;
};

export const StaffAssignmentAvatar = ({
  id,
  email,
  loading,
  fullName,
  className,
  isInactive,
  accountRole,
  actions = null,
  cohortAssignmentRole,
}: Props) => {
  const [openAttendanceScorecard, setOpenAttendanceScorecard] = useState(false);

  return (
    <>
      <div
        className="flex cursor-pointer rounded flex-1 group"
        onClick={() => !loading && setOpenAttendanceScorecard(true)}
      >
        <div
          className={clsx(
            "h-9 w-9 hover:bg-indigo-600 flex flex-center rounded-full",
            className
          )}
        >
          <Icon icon="source" color="text-white" />
        </div>

        <div className="ml-3 flex flex-col">
          {loading ? (
            <LoadingText />
          ) : (
            <>
              <div className="flex gap-2 items-center cursor-pointer">
                <span className="text-sm font-medium text-gray-900 group-hover:text-blue-500 whitespace-wrap">
                  {fullName} {accountRole && getAccountRoleText(accountRole)}
                </span>
              </div>

              <span className="text-sm text-gray-500">{email}</span>

              {cohortAssignmentRole && (
                <CohortAssignmentRoleText
                  className="text-sm font-medium text-gray-500"
                  cohortAssignmentRole={cohortAssignmentRole}
                />
              )}

              {isInactive && (
                <span className="text-sm text-red-500 font-medium">
                  Inactive Account
                </span>
              )}

              {actions}
            </>
          )}
        </div>
      </div>

      <AttendanceScorecardModal
        userId={id}
        show={openAttendanceScorecard}
        closeModal={() => {
          setOpenAttendanceScorecard(false);
        }}
      />
    </>
  );
};
