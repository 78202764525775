import { Routes } from "@utils/routes";
import { ButtonLink, Icon } from "components/shared";

type Props = {
  revieweeId: string;
  cohortId: string;
  sessionId: string;
};

export const TeacherSurveyLinks = (props: Props) => (
  <div className="flex flex-col gap-y-1 pt-2">
    <ButtonLink
      height="xs"
      openInNewTab
      className="flex items-center !leading-none h-fit lg:h-auto min-h-7 py-2 lg:py-0 gap-x-1"
      href={Routes.surveys.professionalExpectations.href(props)}
    >
      Professional Expectations Feedback
      <Icon
        icon="externalLink"
        color="text-white"
        size={4}
        className="mb-[2px] -mr-1.5"
      />
    </ButtonLink>
  </div>
);
